import React, {Component} from 'react';
import Softskillsitem from './Softskillsitem';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Softskillscarousel extends Component{
    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all 0.75s linear"
            transitionDuration={7500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            deviceType={this.props.deviceType}
            itemClass="carousel-item-padding-40-px"
            >
                {this.props.softSkills.map((item) => (
                    <Softskillsitem key={item.id} skillDetail={item} />
                ))}
            </Carousel>
        )
    }
}

export default Softskillscarousel