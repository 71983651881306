import React, {Component} from 'react';
import Logo from '../img/logo.png';
import {Navbar, Nav} from 'react-bootstrap';

class Header extends Component{
    render(){
        return(
            <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
            <Navbar.Brand href="#" className="logo-cont"><img src={Logo} id="logo" alt="Logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="nav-bar"> 
                    <Nav.Link className="nav-item" href="#myself">Myself</Nav.Link>
                    <Nav.Link className="nav-item" href="#latest">Latest Projects</Nav.Link>
                    <Nav.Link className="nav-item" href="#websites">Websites</Nav.Link>
                    <Nav.Link className="nav-item" href="#first">First Projects</Nav.Link>
                    <Nav.Link className="nav-item" href="#experiences">Experience</Nav.Link>
                    <Nav.Link className="nav-item" href="#contact">Get In Touch</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
            // <header className="header">
            //     <div className="logo-cont">
            //         <img id="logo" alt="logo" src={Logo} />
            //     </div>
            //     <nav className="main-nav">
            //         <ul className="nav-bar">
            //             <li className="nav-item"><Link to="myself" spy={true} smooth={true}>Myself</Link></li>
            //             <li className="nav-item"><Link to="latest" spy={true} smooth={true}>Latest Projects</Link></li>
            //             <li className="nav-item"><Link to="websites" spy={true} smooth={true}>Websites</Link></li>
            //             <li className="nav-item"><Link to="first" spy={true} smooth={true}>First Projects</Link></li>
            //             <li className="nav-item"><Link to="experiences" spy={true} smooth={true}>Experience</Link></li>
            //         </ul>
            //     </nav>
            //     <nav className="sub-nav">
            //         <ul className="sub-navBar">
            //             <li className="nav-item"><Link to="contact" spy={true} smooth={true}>Get In Touch</Link></li>
            //         </ul>
            //     </nav>
            // </header>
        )
    }
}

export default Header;