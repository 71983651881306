import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Contactformtitle from './sub_components/Contactformtitle';
import Contactformitems from './sub_components/Contactformitems';
import Contactformmessage from './sub_components/Contactformmessage';

class Contactform extends Component{
    handleClick = (e) => {
        this.props.onClick(e)
    }
    render(){
        return(
            <div id="contact" className="parent-cont">
                <Row>
                    <Col lg={12}>
                        <Contactformtitle />
                    </Col>
                </Row>
                <Contactformitems onClick={this.handleClick}/>
                <Row>
                    <Col lg={12}>
                        <Contactformmessage formErrMess={this.props.formErrMess} sendErr={this.props.sendErr} isSent={this.props.isSent}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Contactform