import React, {Component} from 'react';

class Firstprojbrief extends Component{
    render(){
        return(
            <div className="project-brief-cont">
                <p className="text-center side-padding">
                    When beginning my journey as a developer, I began with the usual free Codeacademy courses, to which I became obsessed with, so much so,
                    that i decided to quit my job at the time and enrol on a full-time, self-funded, development crash course. I thoroughly enjoyed it from
                    the outset and knew that I'd found the sort of role I wanted to do for the rest of my career. As part of learning these new skills, I undertook
                    a variety of projects for fun, to cement and enhance the seemingly constant stream of new skills I was picking up day in day out. Below can be
                    found 4 of the very first projects I built as part of this process, all using HTML5, CSS3 and Javascript. To do this day, these are some of the
                    projects that i'm still most proud of as when I look back at them, not only is it an opportunity to see how far i've progressed since then, but it
                    marked the start of the journey I now find myself on.
                </p>
            </div>
        )
    }
}

export default Firstprojbrief;