import React, {Component} from 'react';


class Heroheading extends Component{
    render(){
        return(
            <div className="grid-container">
                <div id="grid-item1">
                    <h2 className="upper poppins white text-shadow xs-dev-font">Welcome to my <span className="blue upper poppins">portfolio</span>!</h2>
                </div>
                <div id="grid-item2">
                    <h2 className="upper poppins white text-shadow xs-dev-font">My name is <span className="blue upper poppins">Tom Allen</span></h2>
                </div>
                <div id="grid-item3">
                    <h2 className="upper poppins white text-shadow xs-dev-font">I'm a <span className="blue upper poppins">Front End</span> Dev</h2>
                </div>
            </div>
        )
    }
}

export default Heroheading;