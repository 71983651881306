import React, {Component} from 'react';
import CV from '../docs/Dec_20_cv.pdf';

class Cv extends Component{
    render(){
        return(
            <div className="parent-cont">
                <p className="text-center side-padding">
                    Hopefully this now gives a sense of the skillset i'd be able to bring to a role! If you'd like to get further information
                    or view my full work history to date, you can download my latest CV by clicking the link below:
                </p>
                <a id="cv" href={CV} target="_blank" rel="noreferrer">Download CV Here</a>
            </div>
        )
    }
}

export default Cv