import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Experiencetitle from './sub_components/Experiencetitle';
import Experiencetimeline from './sub_components/Experiencetimeline';

class Experiences extends Component{
    render(){
        return(
            <div id="experiences" className="parent-exp-cont">
                <Row>
                    <Col lg={12}>
                        <Experiencetitle />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Experiencetimeline timeLineItems={this.props.experiences} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Experiences