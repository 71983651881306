import React, {Component} from 'react';

class Latestprojectstitle extends Component{
    render(){
        return(
            <h2 className="poppins text-center title-margin">Some of my latest projects</h2>
        )
    }
}

export default Latestprojectstitle;