import React, {Component} from 'react';

class Firstprojectstitle extends Component{
    render(){
        return(
            <h2 className="poppins text-center title-margin extra-margin">My First ventures into development</h2>
        )
    }
}

export default Firstprojectstitle;