import React, {Component} from 'react';

class Softskillstitle extends Component{
    render(){
        return(
            <h2 id="soft-skills-title" className="poppins text-center title-margin text-small">Soft Skills</h2>
        )
    }
}

export default Softskillstitle