import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';

class Firstprojmodal extends Component{
    closeModal = () =>{
        this.props.closeModal();
    }
    render(){
        if(this.props.selModId === "1")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.better[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.better[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.better[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="http://betteryoufit.co.uk/">
                                View Project
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        else if(this.props.selModId === "2")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.quiz[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.quiz[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.quiz[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="http://t-allen.uk/quizmaster/">
                                View Project
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        else if(this.props.selModId === "3")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.goingAway[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.goingAway[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.goingAway[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="http://t-allen.uk/goingaway/">
                                View Project
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        else if(this.props.selModId === "4")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.crescita[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.crescita[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.crescita[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="http://t-allen.uk/crescita/">
                                View Project
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        else{
            return null
        }
    }
}

export default Firstprojmodal