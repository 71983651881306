import React, {Component} from 'react';

class Websiteprojectstitle extends Component{
    render(){
        return(
            <h2 className="poppins text-center title-margin white">Helping out local businesses</h2>
        )
    }
}

export default Websiteprojectstitle;