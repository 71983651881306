import React, {Component} from 'react';

class Techskillsitem extends Component{
    render(){
        const {image_src, title} = this.props.skillDetail;
        return(
            <>
                <div className="skill-container">
                    <div className="skill-img-cont">
                        <img className="skill-img" alt="skill summary" src={require('../../img/' + image_src).default} />
                    </div>
                    <div className="skill-desc-cont">
                        <p>{title}</p>
                    </div>
                </div>
            </>
        )
    }
}

export default Techskillsitem