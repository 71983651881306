import React, {Component} from 'react';

class Experiencetitle extends Component{
    render(){
        return(
            <h2 className="poppins text-center title-margin">Experience</h2>
        )
    }
}

export default Experiencetitle;