import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Headshot from './sub_components/Headshot';
import Aboutme from './sub_components/Aboutme';
import Myselftitle from './sub_components/Myselftitle';

class Myself extends Component{
    render(){
        return(
        <div id="myself" className="myself-cont">
            <Row>
                <Col lg={12}>
                    <Myselftitle />
                </Col>
            </Row>
            <Row>
                <Col lg={5} md={12}>
                    <Headshot />
                </Col>
                <Col lg={7} md={12}>
                    <Aboutme />
                </Col>
            </Row>
        </div>
        
        )
    }
}

export default Myself;