import React, {Component} from 'react';
import {Col} from 'react-bootstrap';

class Websiteprojectsitems extends Component{
    handleClick = (e) => {
        this.props.handleClick(e.target.id)
    }
    render(){
        const {image_src, title, built_with, id} = this.props.projectInfo;
        return(
                <Col lg={4} md={12}>
                    <div className="proj-cont">
                        <div className="proj-title-cont">
                            <h2 className="text-small text-center poppins">{title}</h2>
                        </div>
                        <div className="proj-img-cont">
                            <img className="proj-img" alt="project screenshot" src={require('../../img/' + image_src).default} />
                        </div>
                        <div className="built-with-cont">
                            <h2 className="text-small text-center poppins">Built With: {built_with}</h2>
                        </div>
                        <div className="modal-btn-cont">
                            <button id={id} className="modal-btn" onClick={this.handleClick}>View Project</button>
                        </div>
                    </div>
                </Col>
        )     
    }
}

export default Websiteprojectsitems