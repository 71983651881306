import React, {Component} from 'react';

class Mainhero extends Component{
    render(){
        return(
            <div className="main-hero"></div>
        )
    }
}

export default Mainhero;