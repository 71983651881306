import React, {Component} from 'react';

class Aboutme extends Component{
    render(){
        return(
            <div className="about-me-cont">
                <p className="about-me">As far back as I can remember, I have always had a passion for computing &amp; technology, originally inspired
                in my teens by a keen enthusiasm for gaming and regularly finding myself questioning the mechanisms behind it - <i>how do they do it? </i>
                After leaving college, reflecting, and thinking about this next phase of life, I decided to put university on hold and try my luck in the 
                corporate world by diving headfirst into full time work to learn on the job. Despite experiencing successful careers in both finance &amp; sales,
                I felt an underlying sense of unfullfillment.

                Initially starting with some basic CodeAcademy courses, my natural apetite for development took off. Now with a variety of skills from the fundamental
                HTML5, CSS &amp; Javascript, I continue to develop new skills in my spare time, with my current focus being ReactJS. As I see it, the world of development
                is an ocean and I'm standing on the beach with my toes in, making continue improvements, day in, day out, to bring benefit to whatever company or role I find myself in.

                Aside from tech, I have a deep love for electronic music and bouldering, either DJing or climbing in most of my spare time. Whether it be perfecting code, a mix, or a climb,
                there's a common pursuit for precision that drives me.
                </p>
            </div>
        )
    }
}

export default Aboutme;