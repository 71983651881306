import React, {Component} from 'react';

class Experiencetimelineitem extends Component{
    render(){
        const {title, description, month, year} = this.props.experienceItem
        return(
            <div className="timeline">
                <div className="icon"></div>
                <div className="date-content">
                    <div className="date-outer">
                        <span className="date">
                                <span className="month poppins">{month}</span>
                        <span className="year poppins">{year}</span>
                        </span>
                    </div>
                </div>
                <div className="timeline-content">
                    <h5 className="title poppins">{title}</h5>
                    <p className="description">
                        {description}
                    </p>
                </div>
            </div>
        )
    }
}

export default Experiencetimelineitem