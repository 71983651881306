import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Firstprojectstitle from './sub_components/Firstprojectstitle';
import Firstprojbrief from './sub_components/Firstprojbrief';
import Firstprojectsitems from './sub_components/Firstprojectsitems';

class Firstprojects extends Component{
    handleClick = (e) => {
        this.props.selModal(e);
    }
    render(){
        return(
            <div id="first" className="parent-proj-cont">
                <Row>
                    <Col lg={12}>
                        <Firstprojectstitle />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Firstprojbrief />
                    </Col>
                </Row>
                <Row>
                    {this.props.firstProjects.map((item) => (
                        <Firstprojectsitems handleClick={this.handleClick} key={item.id} projectInfo={item}/>
                    ))}
                </Row>
            </div>
        )
    }
}

export default Firstprojects