import React, {Component} from 'react';

class Contactformitems extends Component{
    constructor(props){
        super(props);
        this.state = {
            formErrInd: false, 
            formErrAry: [],
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const elements = document.getElementById('contactForm').elements
        const checkAry = []
        for(var i = 0; i < elements.length; i++)
        {
            if(elements[i].value === "")
            {
                checkAry.push(elements[i].name);
            }else{
                continue;
            }
        }
        if(checkAry.length > 0)
        {
            this.setState( {formErrInd: true,
                            formErrAry: checkAry}, () => {
                                this.handleErr()
                            })
        }else{
            this.props.onClick(e)
        }
        
    }
    handleErr = () => {
        // const formErr = this.state.formErrAry;
        // const validMsg = [...document.getElementsByClassName('validMsg')];
        // let errIdAry = [];
        // for(var i = 0; i < validMsg.length; i++)
        // {
        //     errIdAry.push(validMsg[i].id);
        // }
        
        // for(var a = 0; a < formErr.length; a++)
        // {
        //     let search = formErr[a];
        //     for(var x = 0; x < errIdAry.length; x++)
        //     {
        //         if(search === errIdAry[x])
        //         {

        //         }
        //     }
        // }

        console.log("hello")
     
        // for(var i = 0; i < formErr.length; i++)
        // {
        //     let eleName = formErr[i].name
        //     for(var x = 0; x < validMsg.length; x++)
        //     {
        //         if(validMsg[x].id === eleName)
        //         {
        //             validMsg[i].classList.remove('hidden');
        //             validMsg[i].classList.add('display');
        //         }else{
        //             continue;
        //         }
        //     }
        // }
    }
    render(){
        return(
            <div className="form-container">
                <form onSubmit={this.handleSubmit} id="contactForm">
                    <div className="form-row">
                        <div className="flex-row">
                            <label htmlFor="first_name" className="form-label">
                                First Name<span className="required">*</span>
                                <span id="first_name" className="validMsg hidden text-xtrasmall">Required Field</span>
                            </label>
                        </div>
                        <div className="flex-row">
                            <label htmlFor="surname" className="form-label">
                                Surname<span className="required">*</span>
                                <span id="surname" className="validMsg hidden text-xtrasmall">Required Field</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="flex-row">
                            <input type="text" name="first_name" className="form-input" placeholder="Enter your first name..."/>
                        </div>
                        <div className="flex-row">
                            <input type="text" name="surname" className="form-input" placeholder="Enter your surname..."/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="flex-row">
                            <label htmlFor="email" className="form-label">
                                Email<span className="required">*</span>
                                <span id="email" className="validMsg hidden text-xtrasmall">Required Field</span>
                            </label>
                        </div>
                        <div className="flex-row">
                            <label htmlFor="subject" className="form-label">
                                Subject<span className="required">*</span>
                                <span id="subject" className="validMsg hidden text-xtrasmall">Required Field</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="flex-row">
                            <input type="email" name="email" className="form-input" placeholder="Enter your email..."/>
                        </div>
                        <div className="flex-row">
                            <input type="text" name="subject" className="form-input" placeholder="Enter a subject..."/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="flex-row">
                            <label htmlFor="message" className="form-label">
                                Message<span className="required">*</span>
                                <span id="message-box" className="validMsg hidden text-xtrasmall">Required Field</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="message-row">
                            <textarea name="message" className="message-box" placeholder="Your message..." style={{resize: "none"}}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="flex-row">
                            <input type="submit" className="form-btn" value="Send" />
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Contactformitems