import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Softskillstitle from './sub_components/Softskillstitle';
import Techskillstitle from './sub_components/Techskillstitle';
import Techskillscarousel from './sub_components/Techskillscarousel';
import Softskillscarousel from './sub_components/Softskillcarousel';

class Skills extends Component{
    render(){
        return(
            <div className="parent-skills-cont">
                <Row>
                    <Col lg={6}>
                        <Techskillstitle />
                    </Col>
                    <Col lg={6}>
                        <Softskillstitle />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Techskillscarousel techSkills={this.props.techSkills}/>
                    </Col>
                    <Col lg={6}>
                        <Softskillscarousel softSkills={this.props.softSkills}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Skills