import React, {Component} from 'react';

class Bottombackground extends Component{
    render(){
        return(
            <div id="bottom-bg"></div>
        )
    }
}

export default Bottombackground