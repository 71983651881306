import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import Latestprojectstitle from './sub_components/Latestprojectstitle';
import Latestprojbrief from './sub_components/Latestprojbrief';
import Latestprojectsitems from './sub_components/Latestprojectsitems';

class Latestprojects extends Component{
    handleClick = (e) => {
        this.props.selModal(e)
    }
    render(){
        return(
            <div id="latest" className="parent-proj-cont">
                <Row>
                    <Col lg={12}>
                        <Latestprojectstitle />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Latestprojbrief />
                    </Col>
                </Row>
                <Row>
                    {this.props.latestProjects.map((item) => (
                        <Latestprojectsitems handleClick={this.handleClick} key={item.id} projectInfo={item} />
                    ))}
                </Row>
            </div>
            
        )
    }
}

export default Latestprojects;