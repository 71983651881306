import React, {Component} from 'react';

class Latestprojbrief extends Component{
    render(){
        return(
            <div className="project-brief-cont">
                <p className="text-center side-padding">
                    As a developer, I pride myself on always keeping up to date with the latest trends in the industry and this also means teaching
                    myself some of the more modern frameworks/libraries so that I can bring a varied skillset to any role I apply for/work in. With this
                    in mind, my latest focus has been around ReactJS which I wanted to showcase, not only through the build of this portfolio, but also 
                    through the projects below that I undertook in my own spare time. As well as this, I want to demonstrate that I can work with MVC projects. To do so,
                    I decided to undertake a simple project using the PHP framework, Laravel - by building a CRM tool that a business could use to track tasks, customers and much more.
                    One thing i'll never lose site of is that there's always more to learn, whether that be in my own time or within a business that wants to invest
                    in their employees to further their skillset and ultimately add further value to role I find myself in. 
                </p>
            </div>
        )
    }
}

export default Latestprojbrief;