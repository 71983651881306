import React, {Component} from 'react';

class Contactformtitle extends Component{
    render(){
        return(
            <h2 className="poppins text-center title-margin">Get in touch...</h2>
        )
    }
}

export default Contactformtitle;