import React, {Component} from 'react';

class Contactformmessage extends Component{
    render(){
        if(this.props.isSent === true)
        {
            return(
                <h2 className="text-center poppins text-xtrasmall">Thank you for your message! It's been sent successfully!</h2>
            )
        }else if(this.props.sendErr === true)
        {
            return(
                <h2 className="text-center poppins text-xtrasmall">Your message could not be sent at this time. Please try again later.</h2> 
            )
        }else{
            return(
                null 
             )
        }
    }
}

export default Contactformmessage