import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';

class Latestprojmodal extends Component{
    closeModal = () => {
        this.props.closeModal()
    }
    render(){
        if(this.props.selModId === "1")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.crm[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.crm[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.crm[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://github.com/tom94allen/merali_crm">
                                GitHub Repo
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }else if(this.props.selModId === "2")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.news[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.news[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.news[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://github.com/tom94allen/react_news_app">
                                GitHub Repo
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )   
        }
        else if(this.props.selModId === "3")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.cbb[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.cbb[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.cbb[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://github.com/tom94allen/react_visual_site_remake">
                                GitHub Repo
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )   
        }else if(this.props.selModId === "4")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.todo[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.todo[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.todo[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://github.com/tom94allen/react_to_do_app">
                                GitHub Repo
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )   
        }else{
            return(
                null
            )
        }
        
    }
}

export default Latestprojmodal;