import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col} from 'react-bootstrap';
import Mainhero from './components/Mainhero';
import Header from './components/Header';
import Heroheading from './components/Heroheading';
import Myself from './components/Myself';
import Midbackground from './components/Midbackground';
import Bottombackground from './components/Bottombackground';
import './App.css';
import Latestprojects from './components/Latestprojects';
import LatProjData from './data/latest_projects.json';
import WebProjData from './data/websites.json';
import FirstProjData from './data/first_projects.json';
import ExperinceData from './data/experiences.json';
import SoftData from './data/soft_skills.json';
import TechData from './data/tech_skills.json';
import Latestprojmodal from './components/Latestprojmodal';
import Cloudsectiondivider from './components/Cloudsectiondivider';
import Semicircledivider from './components/Semicircledivider';
import Websiteprojects from './components/Websiteprojects';
import Websiteprojmodal from './components/Websiteprojmodal';
import Firstprojects from './components/Firstprojects';
import Firstprojmodal from './components/Firstprojmodal';
import Experiences from './components/Experiences';
import Skills from './components/Skills';
import Cv from './components/Cv';
import Contactform from './components/Contactform';
import emailjs from 'emailjs-com';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      latest_projects: [],
      crm: [],
      news: [],
      cbb: [],
      todo: [],
      betteryoufit: [],
      quizmaster: [],
      goingaway: [],
      crescita: [],
      lsw: [],
      hh: [],
      nbm: [],
      website_projects: [],
      first_projects: [],
      experience: [],
      soft_skills: [],
      tech_skills: [],
      latestProjIsOpen: false,
      websiteProjIsOpen: false,
      firstProjIsOpen: false,
      selModId: '',
      isSent: false,
      sendErr: false,
    }
  }
  componentDidMount = () => {
    this.fakeRequest().then(() => {
      const el = document.getElementById("spinner");
      const cont = document.querySelector(".loading-cont");
      if(el && cont){
        el.remove();
        cont.remove();
        this.setState( {loading: false} )
      }
    })
    this.setProjectDataState()
  }
  fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 5000));
  }
  setProjectDataState = () => {
    const latestProj = LatProjData.rows;
    const websiteProj = WebProjData.rows;
    const firstProj = FirstProjData.rows;
    const experience = ExperinceData.rows;
    const softSkills = SoftData.rows;
    const techSkills = TechData.rows;
    this.setState( {
      latest_projects: latestProj,
      website_projects: websiteProj,
      first_projects: firstProj,
      experience: experience,
      soft_skills: softSkills,
      tech_skills: techSkills,
    }, () => {
      this.setLatProjArys(latestProj, websiteProj, firstProj);
    })
  }
  setLatProjArys = (a, b, c) => {
    let latest_projects = a;
    let website_projects = b;
    let firstProj = c;
    let arrayId1 = [];
    let arrayId2 = [];
    let arrayId3 = [];
    let arrayId4 = [];

    for(var i=0; i < latest_projects.length; i++)
    {
      switch(latest_projects[i].id){
        case "1":
          arrayId1.push(latest_projects[i])
          break;
        case "2":
          arrayId2.push(latest_projects[i]);
          break;
        case "3":
          arrayId3.push(latest_projects[i]);
          break;
        case "4":
          arrayId4.push(latest_projects[i]);
          break;
        default:
          break;
      }
    }
    this.setState( {
      crm: arrayId1,
      news: arrayId2,
      cbb: arrayId3,
      todo: arrayId4
    }, () => {
      this.websiteArys(website_projects, firstProj);
    } )
  }
  websiteArys = (a, b) => {
    let website_projects = a;
    let firstProj = b;
    let arrayId1 = [];
    let arrayId2 = [];
    let arrayId3 = [];
    for(var i=0; i < website_projects.length; i++)
    {
      switch(website_projects[i].id){
        case "1":
          arrayId1.push(website_projects[i])
          break;
        case "2":
          arrayId2.push(website_projects[i]);
          break;
        case "3":
          arrayId3.push(website_projects[i]);
          break;
        default:
          break;
      }
    }
    this.setState( {
      lsw: arrayId1,
      hh: arrayId2,
      nbm: arrayId3
    }, () => {
      this.firstProjArys(firstProj);
    })
  }
  firstProjArys = (a) => {
    let firstProj = a;
    let arrayId1 = [];
    let arrayId2 = [];
    let arrayId3 = [];
    let arrayId4 = [];

    for(var i=0; i < firstProj.length; i++)
    {
      switch(firstProj[i].id){
        case "1":
          arrayId1.push(firstProj[i])
          break;
        case "2":
          arrayId2.push(firstProj[i]);
          break;
        case "3":
          arrayId3.push(firstProj[i]);
          break;
        case "4":
          arrayId4.push(firstProj[i]);
          break;
        default:
          break;
      }
    }
    this.setState( {
      betteryoufit: arrayId1,
      quizmaster: arrayId2,
      goingaway: arrayId3,
      crescita: arrayId4
    } )
  }
  closeLatestProjModal = () => {
    this.setState( {latestProjIsOpen: false} )
  }
  closeWebProjModal = () => {
    this.setState( {websiteProjIsOpen: false} )
  }
  closeFirstProjModal = () => {
    this.setState( {firstProjIsOpen: false} )
  }
  latestProjSelModal = (e) => {
    this.setState( {selModId: e}, () => {
      this.openLatestProjModal();
    } )
  }
  websiteProjSelModal = (e) => {
    this.setState( {selModId: e},() => {
      this.openWebsiteProjModal();
    })
  }
  firstProjSelModal = (e) => {
    this.setState( {selModId: e},() => {
      this.openFirstProjModal();
    })
  }
  openFirstProjModal = () => {
    this.setState( {firstProjIsOpen: true} )
  }
  openWebsiteProjModal = () => {
    this.setState( {websiteProjIsOpen: true} )
  }
  openLatestProjModal = () => {
    this.setState( {latestProjIsOpen: true} )
  }
  handleClick = (e) => {
    emailjs.sendForm('service_wwhnm8t', 'template_e6l9pr6', e.target, 'user_x4JFMUM8RJnVUNf1VZWJu')
    .then((result) => {
        let successInd = result.text;
        if(successInd === "OK")
        {
          this.setState( {isSent: true} )
        }
    }, (error) => {
        let errorInd = error.text;
        if(errorInd === "OK")
        {
          this.setState( {sendErr: true} )
        }
    });
  }
  render(){
    if(this.state.loading === true){
      return null;
    }
    return (
      <div className="App">
        <Mainhero />
        <Container fluid>
          <Row>
            <Col>
              <Header />
            </Col>
          </Row>
          <Heroheading />
        </Container>
        <div className="clear"></div>
        <Container fluid>
          <Myself />
        </Container>
        <Midbackground />
        <Container fluid id="latest-projects">
          <Latestprojects selModal={this.latestProjSelModal} latestProjects={this.state.latest_projects}/>
          <Latestprojmodal crm={this.state.crm} todo={this.state.todo} cbb={this.state.cbb} news={this.state.news} selModId={this.state.selModId} closeModal={this.closeLatestProjModal} isOpen={this.state.latestProjIsOpen} />
        </Container>
        <Cloudsectiondivider />
        <Container fluid id="website-projects">
          <Websiteprojects selModal={this.websiteProjSelModal} websiteProjects={this.state.website_projects}/>
          <Websiteprojmodal lsw={this.state.lsw} hh={this.state.hh} nbm={this.state.nbm} selModId={this.state.selModId} closeModal={this.closeWebProjModal} isOpen={this.state.websiteProjIsOpen} />
        </Container>
        <Semicircledivider />
        <Container fluid id="first-projects">
          <Firstprojects selModal={this.firstProjSelModal} firstProjects={this.state.first_projects} />
          <Firstprojmodal better={this.state.betteryoufit} goingAway={this.state.goingaway} crescita={this.state.crescita} quiz={this.state.quizmaster} selModId={this.state.selModId} closeModal={this.closeFirstProjModal} isOpen={this.state.firstProjIsOpen} />
        </Container>
        <Container fluid id="experiences">
          <Experiences experiences={this.state.experience} />
        </Container>
        <Container fluid id="skills">
          <Skills softSkills={this.state.soft_skills} techSkills={this.state.tech_skills}/>
          <Cv />
        </Container>
        <Bottombackground />
        <Container fluid id="contact-form">
          <Contactform sendErr={this.state.sendErr} isSent={this.state.isSent} onClick={this.handleClick}/>
        </Container>
      </div>
    );
  }
}

export default App;
