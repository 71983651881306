import React, {Component} from 'react';

class Semicircledivider extends Component{
    render(){
        return(
            <div className="line-separator"></div>
        )
    }
}

export default Semicircledivider