import React, {Component} from 'react';
import Experiencetimelineitem from './Experiencetimelineitem';

class Experiencetimeline extends Component{
    render(){
        return(
            <div className="main-timeline">
                {this.props.timeLineItems.map((item) => (
                    <Experiencetimelineitem key={item.id} experienceItem={item}/>
                ))}
            </div>
        )
    }
}

export default Experiencetimeline