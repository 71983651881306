import React, {Component} from 'react';
import Websiteprojectstitle from './sub_components/Websiteprojectstitle';
import Websiteprojbrief from './sub_components/Websiteprojbrief';
import Websiteprojectsitems from './sub_components/Websiteprojectsitems';
import {Row, Col} from 'react-bootstrap'

class Websiteprojects extends Component{
    handleClick = (e) => {
        this.props.selModal(e)
    }
    render(){
        return(
            <div id="websites" className="parent-proj-cont">
                <Row>
                    <Col lg={12}>
                        <Websiteprojectstitle />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Websiteprojbrief />
                    </Col>
                </Row>
                <Row>
                    {this.props.websiteProjects.map((item) => (
                        <Websiteprojectsitems handleClick={this.handleClick} key={item.id} projectInfo={item}/>
                    ))}
                </Row>
            </div>
        )
    }
}

export default Websiteprojects