import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';

class Websiteprojmodal extends Component{
    closeModal = () =>{
        this.props.closeModal();
    }
    render(){
        if(this.props.selModId === "1")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.lsw[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.lsw[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.lsw[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://londonsoftwash.co.uk/">
                                View Website
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        else if(this.props.selModId === "2")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.hh[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.hh[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.hh[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://heffernanshemp.com/">
                                View Website
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        else if(this.props.selModId === "3")
        {
            return(
                <Modal show={this.props.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="poppins text-small">
                                {this.props.nbm[0].title}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body-cont">
                            <div className="proj-img-cont">
                                <img alt="project preview" className="proj-img" src={require('../img/' + this.props.nbm[0].image_src).default} />
                            </div>
                            <div className="proj-desc-title">
                                <h2 className="text-small poppins underline">About this project</h2>
                            </div>
                            <div className="proj-desc-cont">
                                <p className="text-xtrasmall">{this.props.nbm[0].description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary">
                            <a target="_blank" rel="noreferrer" href="https://nenucobymaggie.co.uk/">
                                View Website
                            </a>
                        </Button>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }else{
            return null
        }
    }
}

export default Websiteprojmodal