import React, {Component} from 'react';

class Websiteprojbrief extends Component{
    render(){
        return(
            <div className="project-brief-cont">
                <p className="text-center side-padding white">
                    2020, more than any, has been a year which has proven the importance in coming together as one. For this reason, with my full time job being quiet, I took it upon myself to look to help local businesses
                    by using a skill of mine - building websites. A website is arguably now one of the most important aspects of any business, allowing
                    you to reach a wider audience than ever imagined. In partnership with a long term friend, we setup 7A Designs. A digital marketing agency
                    looking to help take small/medium sized businesses to the next level - my task being solely responsible for the whole SDLC from requirements,
                    all the way to deploment &amp; maintenance, with a particular enjoyment for the customer interaction side of things. I'm incredibly proud of what I've managed to achieve to date with 7A, especially as it's been
                    completely in my own time, outside of work &amp; my development studies. Below are three examples of websites i've built for small businesses
                    in my time running the tech side of 7A.  
                </p>
            </div>
        )
    }
}

export default Websiteprojbrief;