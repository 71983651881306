import React, {Component} from 'react';
import Headshotimg from '../../img/head-shot.jpg';

class Headshot extends Component{
    render(){
        return(
            <figure className="headshot-cont">
                <img id="headshot" src={Headshotimg} alt="Headshot of me" />
                <figcaption id="caption">A finance background, sabotaged by a love for tech &amp; travel.</figcaption>
            </figure>
        )
    }
}

export default Headshot;