import React, {Component} from 'react';

class Midbackground extends Component{
    render(){
        return(
            <div id="mekong-bg"></div>
        )
    }
}

export default Midbackground;

